import { Box, Button, Icon, Typography } from '@branch-messenger/willow-ui'
import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import EmailIcon from '@/assets/icons/email.svg'
import { Description, Title } from '@/components/typography'
import { VerificationTimer } from '@/components/verification/VerificationTimer'

interface Props {
  email: string
  navigateTo?: string
  handleNavigate?: () => void
  resendCode?: () => void
}

export const AccountUpdateEmailSent: FC<Props> = ({
  email,
  resendCode,
  navigateTo = '/account',
  handleNavigate,
}) => {
  const navigate = useNavigate()

  const navigateHandler = () => {
    if (handleNavigate) {
      return handleNavigate()
    }
    return navigate({
      to: navigateTo,
    })
  }

  return (
    <Box $display="flex" $align="start" $direction="column" $fullWidth>
      <Box $display="flex" $justify="center" $fullWidth>
        <Icon Icon={EmailIcon} style={{ width: 136, height: 128 }} />
      </Box>
      <Box $display="flex" $direction="column" $align="start" $gap={4} $mt={8}>
        <Title>Check your email.</Title>
        {email && (
          <Description>
            {`To keep your account safe, please verify it's you using the
            verification link sent to:`}{' '}
            <strong>{email}.</strong>
          </Description>
        )}
        <Typography $bold>Do not share this link with anyone.</Typography>
      </Box>
      {resendCode && (
        <VerificationTimer onResendCode={resendCode} type="email" />
      )}
      <Box $mt={6} style={{ marginRight: 'auto' }}>
        <Button onClick={navigateHandler}>
          {handleNavigate ? 'Continue' : 'Go Back'}
        </Button>
      </Box>
    </Box>
  )
}
