import { useSuspenseQuery } from '@tanstack/react-query'
import { FC } from 'react'

import { CodeVerificationForm } from '@/components/verification/CodeVerificationForm'
import { resetPasswordOTPQueryOptions } from '@/queries/reset-password'

interface Props {
  setStep: (step: number) => void
  setOTP: (code: string) => void
  track_id: string
}

export const RequestPasswordCodeVerification: FC<Props> = ({
  setStep,
  setOTP,
  track_id,
}) => {
  const sendResetPasswordOtpCode = useSuspenseQuery(
    resetPasswordOTPQueryOptions(track_id)
  )

  const resendCode = () => {
    sendResetPasswordOtpCode.refetch()
  }

  const proceed = (code: string) => {
    setOTP(code)
    setStep(1)
  }

  return (
    <CodeVerificationForm
      verifyCodePending={false}
      requestCodePending={sendResetPasswordOtpCode.isPending}
      onSubmit={proceed}
      onResendCode={resendCode}
      verificationChannel="PHONE"
    />
  )
}
