import { FC, useState } from 'react'

import { handleSendDeepLinkError } from '@/queries/lib/handleSendDeepLinkError'
import { useSendDeepLink } from '@/queries/registration'
import { InputType } from '@/types/registration'

import { LinkCreated } from './LinkCreated'
import { PhoneEmailForm, PhoneEmailValues } from './PhoneEmailForm'
import { SEONCheck } from './SEONCheck'

export const LinkCreation: FC = () => {
  const [inputType, setInputType] = useState<InputType>(InputType.EMAIL)
  const [phoneOrEmail, setPhoneOrEmail] = useState<string>('')
  const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false)
  const { mutate: sendDeepLink, isPending } = useSendDeepLink()
  const onSubmit = (values: PhoneEmailValues) => {
    const value = values.phone ? values.phone : (values.email ?? '')
    const inputTypeValue = values.phone ? InputType.PHONE : InputType.EMAIL
    const data =
      inputTypeValue === InputType.PHONE ? { phone: value } : { email: value }
    setPhoneOrEmail(value)
    setInputType(inputTypeValue)
    sendDeepLink(data, {
      onSettled: (_, error) => {
        const { accountNeedsSetup, employeeAlreadyEnrolled } =
          handleSendDeepLinkError({ error, reportToSentry: false })

        if (!accountNeedsSetup && !employeeAlreadyEnrolled) {
          setShowSuccessScreen(true)
        }
      },
    })
  }

  if (isPending) return <SEONCheck />

  if (showSuccessScreen)
    return (
      <LinkCreated
        inputType={inputType}
        phoneOrEmail={phoneOrEmail}
        resetForm={() => setShowSuccessScreen(false)}
      />
    )

  return <PhoneEmailForm onSubmit={onSubmit} />
}
