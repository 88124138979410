import { useNavigate, useSearch } from '@tanstack/react-router'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Button, IconButton } from '@/components/button'
import { Input } from '@/components/input'
import { PasswordInput } from '@/components/input/PasswordInput'
import { Box } from '@/components/layout'
import { Loader } from '@/components/loader'
import { Description, Title, Typography } from '@/components/typography'
import { useLogin } from '@/queries/auth'

export const LoginFormContent: FC = () => {
  const loginRequest = useLogin()
  const navigate = useNavigate()
  const { org_name } = useSearch({ from: '/login' })
  const {
    register,
    control,
    formState: { isValid },
  } = useFormContext()

  const resetPassword = () =>
    navigate({
      to: '/request-password-reset',
    })

  return (
    <>
      <Title>Welcome Back</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        {`Login to your account and take control of how you're paid${org_name ? ` with ${org_name}` : ''}.`}
      </Description>
      <Box direction="column">
        <Input
          {...register('email')}
          type="email"
          placeholder="Email Address"
          css={{ mb: '$12' }}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <PasswordInput onChange={onChange} value={value} onBlur={onBlur} />
          )}
        />
        <Box
          direction="column"
          justify="between"
          align="start"
          css={{ mt: '$24' }}
        >
          <Typography size="sm" color="muted">
            Forgot password?
          </Typography>
          <Button
            type="button"
            mode="link"
            css={{ mt: '$4' }}
            onClick={resetPassword}
          >
            Reset {`>`}
          </Button>
        </Box>
        <IconButton
          css={{ margin: '$16 0 $40 0', mr: 'auto' }}
          type="submit"
          iconRight
          disabled={!isValid || loginRequest.isPending}
        >
          {isValid ? 'Login' : 'Enter Info'}
          {loginRequest.isPending && <Loader />}
        </IconButton>
      </Box>
    </>
  )
}
