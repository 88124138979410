import { VerificationChannel } from './auth'

export enum InputType {
  PHONE = 'phone',
  EMAIL = 'email',
}

export type DeepLinkRequestBody = { email: string } | { phone: string }

export interface VerifyUserRequestBody {
  password: string
  track_id: string
}

export interface VerifyUserQueryParams extends VerifyUserRequestBody {
  org_passcode_required: boolean
}

export interface CreateAccountRequestBody {
  email: string
  password: string
  confirm_password: string
  track_id: string
  terms: Terms[]
}

export interface SignupResponse {
  user_id: string
}
export type LinkAccountsRequestBody = Omit<
  CreateAccountRequestBody,
  'confirm_password'
>

export interface GetTrackIDResponse {
  track_id: string
  org_passcode_required: boolean
}

export type Terms = {
  name: string
  version: string
}

export type TermsDetails = Terms & {
  display_name: string
  url: string
}

export type VerifyUserResponse = {
  email: string
}

export type UpdateUserEmailBody = {
  email: string
  otp: string
}

export type UpdateUserPhoneBody = {
  phone: string
  otp: string
}

export type DetectExistingAccountPayload = {
  org_id: string
  track_id: string
}

export type LinkedOrgs = {
  name: string
  logo_url: string
  match_type: VerificationChannel[]
}

export type DetectExistingAccountResponse = {
  linked_orgs: LinkedOrgs[]
}
