import { useLocation } from '@tanstack/react-router'
import { FC, PropsWithChildren } from 'react'

import defaultPartnerGraphic from '@/assets/images/fallback_partner_graphic.png'

import { BreadCrumb } from '../in-app-nav'
import {
  StyledChildrenContainer,
  StyledInnerContainer,
  StyledPageLayoutContent,
  StyledPageLayoutItem,
  StyledPartnerLogoContainer,
} from './styles'

export const PageLayoutContent: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation()
  const shouldAlignTop = pathname.includes('/account')
  return (
    <StyledPageLayoutContent data-align-top={shouldAlignTop ? 'true' : 'false'}>
      <StyledInnerContainer>
        <StyledPageLayoutItem>
          <BreadCrumb />
          <StyledChildrenContainer>{children}</StyledChildrenContainer>
        </StyledPageLayoutItem>
        <StyledPartnerLogoContainer>
          <img src={defaultPartnerGraphic} alt="partner_graphic" />
        </StyledPartnerLogoContainer>
      </StyledInnerContainer>
    </StyledPageLayoutContent>
  )
}
