import * as AlertDialog from '@radix-ui/react-alert-dialog'
import * as Dialog from '@radix-ui/react-dialog'
import styledComponents from 'styled-components'

import { keyframes, styled } from '@/styles/stitches.config'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.35 },
})

export const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const overlay = {
  backgroundColor: 'black',
  position: 'fixed',
  inset: 0,
  opacity: 0.35,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
}

const content = {
  backgroundColor: 'white',
  borderRadius: '$lg',
  boxShadow: '$dialog',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '$24',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      sm: {
        width: '400px',
      },
      md: {
        width: '500px',
      },
      lg: {
        width: '800px',
      },
      xl: {
        width: '1000px',
      },
      unbound: {
        width: '95vw',
      },
    },
  },
  '@mobile': {
    maxWidth: '85vw',
    maxHeight: '85vh',
    overflow: 'scroll',
  },
  defaultVariants: {
    size: 'md',
  },
}

const title = {
  marginBottom: 0,
}

const description = {
  fontSize: '$16',
}

export const AlertDialogOverlay = styled(AlertDialog.Overlay, {
  ...overlay,
})

export const AlertDialogContent = styled(AlertDialog.Content, { ...content })

export const AlertDialogTitle = styled(AlertDialog.Title, { ...title })

export const AlertDialogDescription = styled(AlertDialog.Description, {
  ...description,
})

export const DialogOverlay = styled(Dialog.Overlay, { ...overlay })
export const DialogContent = styled(Dialog.Content, { ...content })
export const DialogTitle = styled(Dialog.Title, { ...title })
export const DialogDescription = styled(Dialog.Description, { ...description })
export const StyledDialogCloseContainer = styledComponents.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.darkGray};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.rounded};
  color: ${({ theme }) => theme.colors.textLight};
  &:hover {
    cursor: pointer;
  }
`
export const DialogClose = Dialog.Close

export const AlertDialogRoot = styled(AlertDialog.Root, {})
export const DialogRoot = styled(Dialog.Root, {})
export const AlertDialogPortal = styled(AlertDialog.Portal, {})
export const AlertDialogCancel = styled(AlertDialog.Cancel, {})
export const AlertDialogAction = styled(AlertDialog.Action, {})
export const DialogPortal = Dialog.Portal
