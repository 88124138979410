import { Cross2Icon } from '@radix-ui/react-icons'
import { FC } from 'react'

import { Box } from '@/components/layout'
import { DialogProps } from '@/types/dialog'

import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  StyledDialogCloseContainer,
} from './styles'

export const Dialog: FC<DialogProps> = ({
  title,
  open,
  setOpen,
  description,
  children,
}) => (
  <DialogRoot open={open} onOpenChange={setOpen}>
    <DialogPortal>
      <DialogOverlay />
      <DialogContent onOpenAutoFocus={e => e.preventDefault()}>
        <Box justify="between">
          <DialogTitle>{title}</DialogTitle>
          <DialogClose asChild>
            <StyledDialogCloseContainer>
              <Cross2Icon />
            </StyledDialogCloseContainer>
          </DialogClose>
        </Box>
        <DialogDescription>{description}</DialogDescription>
        {children}
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
)
