import { Box, Button, Card, Typography } from '@branch-messenger/willow-ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'
import { useNavigate } from '@tanstack/react-router'
import { FC, useContext } from 'react'

import {
  OrgProfileImage,
  OrgProfileImageContainer,
} from '@/components/page-layout'
import { DialogContext } from '@/context'
import { useAuth } from '@/context/Auth'
import { detectExistingAccountQueryOptions } from '@/queries/registration'

import { ContactSupportContent } from './ContactSupportContent'

export const ExistingAccount: FC = () => {
  const { showDialog } = useContext(DialogContext)
  const { orgId } = useAuth()
  const { track_id } = useSearch({ from: '/setup/existing-account' })
  const { data } = useSuspenseQuery(
    detectExistingAccountQueryOptions({ org_id: orgId, track_id })
  )
  const navigate = useNavigate()
  const existingOrg = data.linked_orgs[0]
  const { logo_url, name, match_type = [] } = existingOrg

  const linkedMethod =
    match_type.length === 1 && match_type[0] === 'PHONE'
      ? 'phone number'
      : 'email address'
  const openContactSupportDialog = () => {
    showDialog({
      title: 'Contact support.',
      description: 'How would you like to be helped?',
      children: <ContactSupportContent />,
    })
  }

  return (
    <Box $display="flex" $direction="column" $align="stretch">
      <Box $mb={6}>
        <Typography $size="xxl" $bold>
          Is this your account?
        </Typography>
      </Box>
      <Box $mb={5}>
        <Typography>{`We found the following account linked to your ${linkedMethod}`}</Typography>
      </Box>
      <Card $mb={11}>
        <Box $display="flex" $gap={4}>
          <OrgProfileImageContainer>
            <OrgProfileImage src={logo_url} alt={name} />
          </OrgProfileImageContainer>
          <Typography $bold>{name}</Typography>
        </Box>
      </Card>
      <Box $display="flex" $gap={3}>
        <Button
          onClick={openContactSupportDialog}
          variant="outlined"
        >{`No, It's Not`}</Button>
        <Button
          onClick={() =>
            navigate({ to: '/login', search: { org_name: name, track_id } })
          }
        >{`Yes, It Is`}</Button>
      </Box>
    </Box>
  )
}
