import { Box, Button, Icon, Typography } from '@branch-messenger/willow-ui'
import { ChevronRight, Home02 } from '@branch-messenger/willow-ui/icons'
import { useLocation, useNavigate } from '@tanstack/react-router'

import { navigationOptions } from './navigation-options'

export const BreadCrumb = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const match = navigationOptions.find(
    option => option.route === location.pathname
  )

  if (!match) return null

  return (
    <Box $display="flex" $justify="start" $fullWidth $gap={1} $p={0}>
      <Button
        iconLeft={<Icon Icon={Home02} />}
        variant="link"
        color="secondary"
        onClick={() => navigate({ to: '/account' })}
        style={{ padding: 0 }}
      >
        Home
      </Button>
      {match?.title && (
        <>
          <Icon Icon={ChevronRight} />
          <Typography $bold $size="sm">
            {match?.title}
          </Typography>
        </>
      )}
    </Box>
  )
}
