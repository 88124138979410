import { Box, Button, Input, Label } from '@branch-messenger/willow-ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { FC, useMemo } from 'react'

import { Title } from '@/components/typography'
import { formatPhoneNumber } from '@/lib/phone'
import { workerInfoQueryOptions } from '@/queries/auth'

export const AccountManagement: FC = () => {
  const navigate = useNavigate()
  const { data: workerInfo = [] } = useSuspenseQuery(workerInfoQueryOptions)

  const formattedPhoneNumber = useMemo(() => {
    if (!workerInfo[0]) return ''
    return formatPhoneNumber(workerInfo[0].phone_number)
  }, [workerInfo])

  const email = workerInfo[0]?.email_address

  return (
    <Box
      $display="flex"
      $direction="column"
      $align="start"
      $justify="start"
      $gap={4}
      style={{ height: '100%' }}
    >
      <Title>Manage your account.</Title>
      <Label $color="textMuted" $uppercase>
        Account Info
      </Label>
      <Input
        value={formattedPhoneNumber}
        $adornmentPosition="right"
        readOnly
        adornment={
          <Button
            variant="link"
            onClick={() => navigate({ to: '/account/phone-update' })}
            color="secondary"
          >
            Update
          </Button>
        }
      />
      <Input
        value={email}
        readOnly
        adornment={
          <Button
            variant="link"
            onClick={() => navigate({ to: '/account/email-update' })}
            color="secondary"
          >
            Update
          </Button>
        }
        $adornmentPosition="right"
      />
    </Box>
  )
}
