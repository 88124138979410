import { Button } from '@branch-messenger/willow-ui'
import styled from 'styled-components'

export const NewOptionItemContainer = styled(Button)`
  display: block;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  &:disabled {
    border-color: white;
    background-color: transparent;
  }
`
