import { Box } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { BookmarkPopup } from '@/components/bookmark-popup/bookmark-popup'
import { Title } from '@/components/typography'
import { PayoutHistory } from '@/containers/PayoutHistory'
import { useOrg } from '@/selectors/useOrg'

import { AccountProfile } from './AccountProfile'

export const AccountOverview: FC = () => {
  const { orgName } = useOrg()

  return (
    <>
      <BookmarkPopup />
      <Box
        $display="flex"
        $direction="column"
        $gap={4}
        $align="start"
        style={{ height: '100%' }}
      >
        <Title>Manage your {orgName} payments.</Title>
        <AccountProfile />
        <PayoutHistory />
      </Box>
    </>
  )
}
