import {
  Box,
  Dropdown,
  DropdownSeparator,
  Icon,
  IconButton,
} from '@branch-messenger/willow-ui'
import {
  HelpCircle,
  LogOut01,
  UserCircle,
} from '@branch-messenger/willow-ui/icons'
import { useNavigate } from '@tanstack/react-router'

import { useLogout } from '@/queries/auth'

import { StyledHelpLink } from '../page-layout/styles'
import { AccountDropdownIcon, AccountDropdownMenuItem } from './styles'

export const AccountDropdown = () => {
  const navigate = useNavigate()
  const logout = useLogout()
  const isAccountRoute = location.pathname.includes('/account')

  const handleLogout = () => logout.mutate()

  const handlePersonalInfo = () => {
    navigate({ to: '/account/management' })
  }

  return (
    <Dropdown
      trigger={
        <IconButton>
          <Icon Icon={UserCircle} size={4} />
        </IconButton>
      }
      showArrow={false}
    >
      <Box
        $display="flex"
        $direction="column"
        $align="start"
        $gap={2}
        $fullWidth
      >
        {isAccountRoute && (
          <>
            <AccountDropdownMenuItem onClick={handlePersonalInfo}>
              <AccountDropdownIcon Icon={UserCircle} />
              Personal Info
            </AccountDropdownMenuItem>
            <DropdownSeparator style={{ width: '100%' }} />
          </>
        )}
        <AccountDropdownMenuItem>
          <AccountDropdownIcon Icon={HelpCircle} />
          <StyledHelpLink
            href="https://support.branchapp.com/hc/en-us/sections/20572256284315-Branch-Direct"
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </StyledHelpLink>
        </AccountDropdownMenuItem>
        {isAccountRoute && (
          <AccountDropdownMenuItem onClick={handleLogout}>
            <AccountDropdownIcon Icon={LogOut01} />
            Sign Out
          </AccountDropdownMenuItem>
        )}
      </Box>
    </Dropdown>
  )
}
