import { Box, Icon } from '@branch-messenger/willow-ui'
import { ComponentType, FC, SVGProps } from 'react'

import { Description, Title } from '@/components/typography'

export interface InformationType {
  icon: ComponentType<SVGProps<SVGSVGElement>>
  title: string
  description: string
}

type Props = InformationType

export const Information: FC<Props> = ({ icon, title, description }) => {
  return (
    <Box $mb={4} $display="flex">
      <Box $mr={4} $display="flex">
        <Icon Icon={icon} size={4} />
      </Box>
      <Box $direction="column" $align="start">
        <Title size="md">{title}</Title>
        {description && (
          <Description css={{ mt: '$4' }} size="xs" color="muted">
            {description}
          </Description>
        )}
      </Box>
    </Box>
  )
}
