import { createFileRoute } from '@tanstack/react-router'

import { ExistingAccount } from '@/containers/ExistingAccount'

type Search = {
  track_id: string
}

export const Route = createFileRoute('/setup/existing-account')({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      track_id: search.track_id as string,
    }
  },
  component: ExistingAccount,
})
