import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'

import { IResponse } from '@/types/common'

export type ExceptionOptionProps<T> = {
  onHttpError: (err: AxiosError<T>) => void
  onOtherError?: (err: unknown) => void
  reportToSentry?: boolean
}

/** Pass an exception error and use the callbacks to handle different error types */
export const handleHttpException = <T = IResponse>(
  error: unknown,
  { onHttpError, onOtherError, reportToSentry = true }: ExceptionOptionProps<T>
) => {
  if (axios.isAxiosError(error)) {
    onHttpError(error)
  } else {
    reportToSentry && Sentry.captureException(error)
    onOtherError?.(error)
  }
}
