import { Box, Typography } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { Button } from '@/components/button'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'

type Props = {
  setStep: (step: number) => void
}

export const TermsOverview: FC<Props> = ({ setStep }) => {
  return (
    <Box $direction="column">
      <Box $justify="center" $mb={8}>
        <SVGIcon iconName="sign_form" />
      </Box>
      <Box $direction="column" $align="start" $fullWidth $mb={4}>
        <Title>Review and agree to account terms.</Title>
        <Description>
          Before moving forward, please review our account terms, policies, and
          agreements.
        </Description>
      </Box>
      <Typography $size="sm" $bold>
        {`You'll need to agree to each one to continue.`}
      </Typography>
      <Box $mt={8}>
        <Button onClick={() => setStep(1)}>Continue</Button>
      </Box>
    </Box>
  )
}
