import { Box, Card } from '@branch-messenger/willow-ui'
import styled, { css } from 'styled-components'

export const TermsAndPoliciesAgreementContainer = styled(Box)`
  display: flex;
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.radii.lg};
  padding: ${props => props.theme.space[4]};
  width: auto;
  margin-top: ${props => props.theme.space[6]};
`

export const TermsContainer = styled(Card)<{ $isMobile?: boolean }>`
  position: relative;
  width: 100%;

  iframe {
    width: 100%;
    height: 55dvh;
    border: none;
  }

  ${({ $isMobile }) => {
    return (
      $isMobile &&
      css`
        iframe {
          height: 65dvh;
        }
      `
    )
  }}
`
