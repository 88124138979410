import { Box, Icon } from '@branch-messenger/willow-ui'
import { ChevronRight } from '@branch-messenger/willow-ui/icons'
import { ComponentType, FC, SVGProps } from 'react'

import { NewOptionItemContainer } from './styles'

interface Props {
  onClick?: () => void
  icon?: ComponentType<SVGProps<SVGSVGElement>>
  disabled?: boolean
  visible?: boolean
  children?: React.ReactNode
  link?: string
}

// new option item with more flexibility and new icon component. to replace OptionItem
export const NewOptionItem: FC<Props> = ({
  onClick,
  icon,
  disabled = false,
  visible = true,
  children,
  link,
}) => {
  if (!visible) return null
  return (
    <Box $fullWidth>
      <NewOptionItemContainer
        onClick={onClick}
        disabled={disabled}
        variant="link"
        color="dark"
        size="lg"
        {...(link
          ? {
              forwardedAs: 'a',
              href: link,
              target: '_blank',
              rel: 'noreferrer',
            }
          : {})}
      >
        <Box $display="flex" $justify={'space-between'} $fullWidth>
          <Box $display="flex" $gap={4}>
            {icon && <Icon Icon={icon} size={4} />}
            {children}
          </Box>
          <Box $display="flex">
            <Icon Icon={ChevronRight} size={6} />
          </Box>
        </Box>
      </NewOptionItemContainer>
    </Box>
  )
}
