import { createFileRoute } from '@tanstack/react-router'

import { Login } from '@/containers/Login'

type Search = {
  track_id?: string
  org_name?: string
}

export const Route = createFileRoute(`/login`)({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      track_id: search.track_id as string,
      org_name: search.org_name as string,
    }
  },
  component: Login,
})
