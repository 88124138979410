import {
  Box,
  Button,
  Icon,
  theme,
  Typography,
} from '@branch-messenger/willow-ui'
import { ChevronRight } from '@branch-messenger/willow-ui/icons'
import { FC, useRef } from 'react'

import { useTimer } from '@/hooks/useTimer'

interface Props {
  type?: 'text' | 'email'
  onResendCode: () => void
}

export const VerificationTimer: FC<Props> = ({
  type = 'text',
  onResendCode,
}) => {
  const counterRef = useRef<HTMLSpanElement>(null)
  const { isCompleted, resetCountDown } = useTimer(1, 0, counterRef)

  const resendCode = () => {
    resetCountDown()
    onResendCode()
  }

  return (
    <Box $direction="column" $align="start" $mt={6}>
      <Typography $size="sm" $color="textMuted">
        Problems receiving {type}?
      </Typography>
      <Typography
        $bold
        $color="textMuted"
        $size="sm"
        style={{
          display: `${isCompleted ? 'none' : 'block'}`,
          marginTop: theme.space[1],
          marginBottom: theme.space[2],
        }}
      >
        Resend {type === 'text' ? 'Code' : 'Link'} in{' '}
        <span ref={counterRef}></span>
      </Typography>
      {isCompleted && (
        <Button
          variant="link"
          color="secondary"
          onClick={resendCode}
          iconRight={<Icon Icon={ChevronRight} />}
        >
          Resend {type === 'text' ? 'Code' : 'Link'}
        </Button>
      )}
    </Box>
  )
}
