import { DropdownMenuItem, Icon } from '@branch-messenger/willow-ui'
import styled from 'styled-components'

export const AccountDropdownMenuItem = styled(DropdownMenuItem)`
  width: 100%;
  justify-content: normal;
`

export const AccountDropdownIcon = styled(Icon).attrs({ size: 4 })`
  margin-right: ${({ theme }) => theme.space[2]};
`
