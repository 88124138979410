import { useLocation } from '@tanstack/react-router'
import { FC, PropsWithChildren } from 'react'

import { useOrg } from '@/selectors/useOrg'

import { PageLayoutContent } from './PageLayoutContent'
import { PageLayoutFooter } from './PageLayoutFooter'
import { PageLayoutHeader } from './PageLayoutHeader'
import { StyledPageLayoutContainer } from './styles'

export const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { orgInfo } = useOrg()
  const { pathname } = useLocation()
  const isAccountRoute = pathname.includes('/account')

  return (
    <StyledPageLayoutContainer>
      <PageLayoutHeader
        logoUrl={orgInfo?.logo_url}
        showOrgSelector={isAccountRoute}
      />
      <PageLayoutContent>{children}</PageLayoutContent>
      <PageLayoutFooter />
    </StyledPageLayoutContainer>
  )
}
