import { CountryCode, isValidNumber, parse } from 'libphonenumber-js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode
) => {
  try {
    const parsedNumber = parse(phoneNumber, countryCode)
    return isValidNumber(parsedNumber)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return false
  }
}

export const formatPhoneNumber = (phoneNumberString: string): string => {
  let phoneNumber = parsePhoneNumberFromString(phoneNumberString)

  // If the phone number does not have a country code, assume it's a US number
  if (!phoneNumber) {
    phoneNumber = parsePhoneNumberFromString(
      phoneNumberString,
      'US' as CountryCode
    )
  }

  if (!phoneNumber || !phoneNumber.isValid()) {
    return 'Invalid Phone Number'
  }

  // Format the phone number
  const formattedNumber = phoneNumber.formatInternational()
  const parts = formattedNumber.split(' ')

  if (parts.length >= 3) {
    return `${parts[0]} (${parts[1]}) ${parts[2]}-${parts.slice(3).join('-')}`
  } else if (parts.length === 2) {
    // Handle case without area code
    return `${parts[0]} ${parts[1].replace(/(\d{3})(\d{4})/, '$1-$2')}`
  }

  return formattedNumber.replace(/\s/g, '-')
}
