import { Box, DropdownMenuItem } from '@branch-messenger/willow-ui'
import styled from 'styled-components'

export const StyledPageLayoutContainer = styled(Box).attrs({
  $display: 'flex',
  $direction: 'column',
})`
  margin: auto;
  min-height: 100svh;
  padding: 0 ${({ theme }) => theme.space[4]};
  @media (max-width: 768px) {
    width: 95vw;
    margin: 0 auto;
    padding: ${({ theme }) => theme.space[4]};
  }
`

export const PageLoadingContainer = styled(Box).attrs({
  $display: 'flex',
  $justify: 'center',
  $align: 'center',
})`
  height: 100svh;
`

export const OrgImage = styled.img`
  height: 35px;
`

export const StyledPageLayoutFooter = styled(Box).attrs({
  $fullWidth: true,
  $display: 'flex',
  $justify: 'space-between',
})`
  height: 50px;
`

export const StyledPageLayoutItem = styled(Box).attrs({
  $display: 'flex',
  $direction: 'column',
  $justify: 'start',
  $align: 'stretch',
  $gap: 4,
  $flex: 1,
})`
  position: relative;
`

export const StyledPartnerLogoContainer = styled(Box).attrs({
  $flex: 1,
})`
  height: 100%;
  max-width: 470px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }

  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    border-radius: 32px;
  }
`

export const StyledOrgProfileImage = styled.img`
  width: 100%;
`

export const StyledOrgProfileImageContainer = styled.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.rounded};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[1]};
`

export const StyledHelpLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textDark};
`

export const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};
`

export const StyledInnerContainer = styled(Box).attrs({
  $display: 'flex',
  $fullWidth: true,
  $gap: 12,
  $align: 'center',
})`
  max-width: 1080px;
`

export const StyledPageLayoutContent = styled(Box).attrs({
  $display: 'flex',
  $fullWidth: true,
  $align: 'normal',
  $justify: 'center',
  $flex: 1,
  $py: 4,
})`
  &[data-align-top='true'] {
    & > ${StyledInnerContainer} {
      align-items: flex-start;
    }
    & ${StyledPartnerLogoContainer} {
      img {
        top: 0;
        transform: translate(-50%, 0);
      }
    }
  }
`

export const StyledChildrenContainer = styled(Box).attrs({
  $display: 'flex',
  $direction: 'column',
  $justify: 'center',
  $align: 'start',
  $fullWidth: true,
})``
