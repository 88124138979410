import { handleHttpException } from '@/lib/httpException'
import { OldIResponse } from '@/types/common'

import { DeepLinkErrorInfo, HandleSendDeepLinkErrorProps } from './types'

export const handleSendDeepLinkError = ({
  reportToSentry,
  error,
}: HandleSendDeepLinkErrorProps) => {
  const data: DeepLinkErrorInfo = {
    errorMessage: 'An unknown error occurred. Please try again later.',
  }
  handleHttpException<OldIResponse>(error, {
    onHttpError: error => {
      const errorMessage = error.response?.data.message
      const status = error.response?.status
      if (status === 409 && errorMessage === 'Employee already enrolled') {
        data.errorMessage = errorMessage
        data.employeeAlreadyEnrolled = true
      } else if (errorMessage === 'Employee email or phone number is missing') {
        data.errorMessage =
          'Org setup has not been completed. Please contact your payroll administrator to proceed with sign up.'
        data.accountNeedsSetup = true
      }
    },
    onOtherError: () => {
      data.isOtherError = true
    },
    reportToSentry,
  })
  return data
}
