import { createFileRoute, redirect } from '@tanstack/react-router'

import { loginWorker } from '@/api/auth'
import { PageLoader } from '@/components/loader'
import { cleanUpCookieandStorage } from '@/lib/token'
import { workerInfoQueryOptions } from '@/queries/auth'
import { orgInfoQueryOptions } from '@/queries/organization'

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context: { queryClient, auth } }) => {
    let orgId = auth.orgId
    if (!orgId) {
      const workerInfo = await queryClient.ensureQueryData(
        workerInfoQueryOptions
      )
      orgId = workerInfo[0].org_uuid as string
      await loginWorker(workerInfo[0])
      if (orgId) auth.setOrgId(orgId)
    }
    if (orgId) {
      return queryClient.ensureQueryData(orgInfoQueryOptions(orgId))
    }
  },
  onError: () => {
    cleanUpCookieandStorage()
    throw redirect({ to: '/login' })
  },
  pendingComponent: PageLoader,
})
