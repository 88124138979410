import { Box } from '@branch-messenger/willow-ui'
import { HelpCircle } from '@branch-messenger/willow-ui/icons'
import { FC, useEffect } from 'react'

import { NewOptionItem } from '@/components/options'
import { sendAnalyticsEvent } from '@/lib/mixpanel'

export const ContactSupportContent: FC = () => {
  useEffect(
    () => sendAnalyticsEvent('ONBOARDING', 'existing-account-support:get-help'),
    []
  )

  return (
    <Box $display="flex" $direction="column">
      <NewOptionItem
        link="https://support.branchapp.com/hc/en-us/sections/20572256284315-Branch-Direct"
        icon={HelpCircle}
      >
        FAQs
      </NewOptionItem>
    </Box>
  )
}
