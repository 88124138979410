export enum PayoutFeeType {
  INSTANT = 'INSTANT',
  DELAYED = 'DELAYED',
}

export interface PayoutFee {
  payout_fee_type: PayoutFeeType
  base_fee: number
  fee_percent: number
  max_fee: number | null
}
