import { RouterProvider } from '@tanstack/react-router'

import { useAuth } from './context/Auth'
import { useToastContext } from './context/ToastContext'
import { queryClient } from './lib/query-client'
import { router } from './lib/router'

export const App = () => {
  const auth = useAuth()
  const toast = useToastContext()
  return (
    <RouterProvider router={router} context={{ auth, queryClient, toast }} />
  )
}
