import { useSuspenseQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { Box, Flex } from '@/components/layout'
import { Typography } from '@/components/typography'
import { formatAccountNumberLast4 } from '@/lib/string-formatting'
import { paymentProfileQueryOptions } from '@/queries/profile'
import {
  BranchDirectCardStatus,
  PaymentType,
  WalletStatus,
} from '@/types/profile'

import { AccountStatusBadge } from './AccountStatusBadge'
import { AccountStatusIcon } from './AccountStatusIcon'
import { StatusIndicator } from './styles'

export const AccountStatus = () => {
  const { data } = useSuspenseQuery(paymentProfileQueryOptions)
  const last4 = useMemo(() => {
    switch (data?.active_type) {
      case PaymentType.CARD:
        return (
          <Typography
            bold
          >{`Debit card ••• ${data?.branch_direct_card_account?.last4}`}</Typography>
        )
      case PaymentType.WALLET:
        return (
          <Typography bold>{`Account ••• ${formatAccountNumberLast4(
            data?.wallet?.account_number
          )}`}</Typography>
        )
      default:
        return (
          <Flex direction="column" align="start">
            <Typography color="muted" italicized>
              No active payout method
            </Typography>
            <Typography color="muted" size="xs" italicized>
              Select a method below
            </Typography>
          </Flex>
        )
    }
  }, [data])

  const connectedAccountStatus = useMemo(() => {
    switch (data?.active_type) {
      case PaymentType.CARD:
        return BranchDirectCardStatus.COMPLETE
      case PaymentType.WALLET:
        return data.wallet?.status === WalletStatus.ACTIVE
          ? BranchDirectCardStatus.COMPLETE
          : data?.wallet?.status
      default:
        return BranchDirectCardStatus.NONE
    }
  }, [data])

  return (
    <>
      <Typography as="h2">Payout Method</Typography>
      <Box css={{ mb: '$16' }}>
        <StatusIndicator>
          <AccountStatusIcon paymentType={data?.active_type} />
        </StatusIndicator>
        <Box align="start">
          <Box direction="column" align="start">
            {last4}
          </Box>
        </Box>
        <Flex css={{ ml: 'auto' }}>
          <AccountStatusBadge status={connectedAccountStatus} />
        </Flex>
      </Box>
    </>
  )
}
