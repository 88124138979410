import { Box, Button } from '@branch-messenger/willow-ui'
import { Bank, CreditCard02, Wallet03 } from '@branch-messenger/willow-ui/icons'
import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Description, Title } from '@/components/typography'

import { Information } from './Information'

export const BranchInformational: FC = () => {
  const navigate = useNavigate()
  const nextStep = () => {
    navigate({ to: '/setup/payment-selection' })
  }

  const infos = [
    {
      icon: Bank,
      title: 'Banking Services',
      description: `Banking services that support Branch’s services are provided by Evolve Bank & Trust, Member FDIC (“Evolve”).`,
    },
    {
      icon: CreditCard02,
      title: 'Debit Card',
      description: `Branch offers workers a Mastercard debit card (the “Branch Card”), issued by Evolve pursuant to a license from Mastercard and may be used everywhere Mastercard is accepted.`,
    },
    {
      icon: Wallet03,
      title: 'Branch Wallet',
      description: `The Branch Card is linked to a bank account at Evolve (“Branch Account” and, together with the Branch Card, the “Branch Wallet”).`,
    },
  ]

  return (
    <Box $direction="column" $align="start">
      <Title>Branch makes pay access simple.</Title>
      <Description size="sm" css={{ mt: '$16' }}>
        Branch is a technology company that provides workers with fast, easy
        access to their earnings. Branch is not a bank.
      </Description>
      <Box $my={10}>
        {infos.map(info => (
          <Information key={info.title} {...info} />
        ))}
      </Box>
      <Box $mb={10}>
        <Description size="xs" italicized color="muted">
          * For fees and other important information about opening a Branch
          Account, visit{' '}
          <a
            href="https://www.branchapp.com/legal/what-is-branch"
            target="_blank"
            rel="noreferrer"
          >
            https://www.branchapp.com/legal/what-is-branch
          </a>
        </Description>
      </Box>
      <Box $mt={4} $mb={10}>
        <Button onClick={nextStep}>Continue</Button>
      </Box>
    </Box>
  )
}
